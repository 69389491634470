<template>
	<div class="mt-4">
		<v-row>
			<v-col cols="12" md="4" sm="12">
				<p class="subtitle-2">
					{{ course.shortDescription }}
				</p>
				<div class="my-1">
					<v-chip :ripple="false" v-for="semester in course.semesters" :key="semester.key" class="mx-1">
						{{ semester.name }}
					</v-chip>
				</div>
				<p><strong>Seen in these degrees:</strong></p>
				<v-card rounded="xl">
					<v-list color="transparent" rounded class="pa-0">
						<v-list-item v-for="degree in course.degrees" :key="degree.id" class="py-2">
							<v-row>
								<v-col cols="12" sm="12">
									<v-list-item-title style="white-space: normal">
										{{ degree.name }}
									</v-list-item-title>
								</v-col>
								<v-col cols="12" sm="12">
									<v-form v-if="degree.practiceGroups.length">
										<v-select
											v-model="practiceGroup"
											:items="degree.practiceGroups"
											:label="$t('courses.practices')"
											:placeholder="$t('studies.selectGroup')"
											outlined
											@change="getPracticeSchedule(degree)"
										/>
									</v-form>
								</v-col>
							</v-row>
						</v-list-item>
					</v-list>
				</v-card>
			</v-col>
			<v-col cols="12" md="8" sm="12">
				<p class="body-1">[{{ course.faculty.university.acronym }}] {{ course.faculty.university.name }}</p>
				<p class="body-1">[{{ course.faculty.acronym }}] {{ course.faculty.name }}</p>
				<p class="body-2 text-justify" style="white-space: pre-line">
					{{ course.longDescription }}
				</p>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	metaInfo() {
		return {
			title: `${this.$t('courses.details')} - ${this.course.name}`,
		}
	},
	data() {
		return {
			practiceGroup: '',
		}
	},
	computed: {
		...mapGetters({
			course: 'courses/course',
			availableSemesters: 'data/availableSemesters',
		}),
	},
	created() {
		this.fetchSemesters()
	},
	methods: {
		getPracticeSchedule(degree) {
			this.downloadSchedule({ courseID: this.course.id, degreeID: degree.id, group: this.practiceGroup })
		},
		...mapActions('courses', ['downloadSchedule']),
		...mapActions('data', ['fetchSemesters']),
	},
}
</script>

<style scoped></style>
